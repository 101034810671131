import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import PageEnum from '@/enums/pageEnum';
import { history } from '@@/core/history';
import { Image } from '@nextui-org/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReq } from '@/services/net/request';
import { getHelpCenterListApi } from '@/services/api/requestApi';

export default () => {
  const { t } = useTranslation();
  const [key, setKey] = useState('');
  const {
    data: helpCenterList = [] as any[],
    loading,
  } = useReq(getHelpCenterListApi, {
    onSuccess: (res) => {},
    formatResult: (res: any) => {
      return res?.data?.list ?? [];
    },
  });
  return (
    <div>
      <BaseTopNav />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('帮助文档')}
        description={t('搜索常见问题')}
      />
      <div className="flex items-center pb-3 px-4 w-full sticky top-0 bg-background z-50">
        <div className="text-center flex-1">
          <BaseInput
            className="bg-backgroundAuxiliaryColor overflow-hidden mt-2 mb-3 h-[40px]"
            value={key}
            startContent={
              <div>
                <BasilSearchSolid />
              </div>
            }
            onChange={(e) => setKey(e.target.value)}
            placeholder={t('搜索')}
            classNames={{
              base: 'rounded-md overflow-hidden h-[40px]',
              inputWrapper:
                'h-full border-none rounded-[30px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
            }}
          ></BaseInput>
        </div>
      </div>
      <div>
        <div className="text-base mb-6 pl-4">{t('常见问题')}</div>
        <BaseLoadingDisplay loading={loading} list={helpCenterList as any[]}>
          <div className="grid gap-y-14 grid-cols-2">
            {(helpCenterList as any)
              ?.filter((item: any) => {
                if (key) {
                  return item.title.includes(key.trim());
                } else {
                  return item;
                }
              })
              ?.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col justify-start items-center"
                    onClick={() => {
                      history.push(
                        PageEnum.HELPARTICLEDETAIL + '?id=' + item.id,
                      );
                    }}
                  >
                    <Image
                      className="w-16 h-16 rounded-md gap-y-4 object-cover"
                      src={item.imgUrl}
                    />
                    <div className="mt-3 px-4">{item.title}</div>
                  </div>
                );
              })}
          </div>
        </BaseLoadingDisplay>
      </div>
    </div>
  );
};
